<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkInputText from "@components/input/AkInputText";
import AkDropdown from "@components/input/AkDropdown";
import AkDialog from "@components/general/AkDialog";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import uerService from "@services/uerService";
import agerService from "@services/agerService";
import rolePermissionService from "@services/rolePermissionService";

export default {
  components: {AkFormList, AkInputText, AkDropdown, AkDialog}, 
  mixins: [randomRef, utilsMixin, roleMixin],
  metaInfo() {
    return {
      title: "uer.list",
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      list: [],
      current: {},
      agerList: [],
      loading: false,
    }
  },
  validations() {
    return {
      current: {
        label: {required},
        agerId: {required},
        importKey: {required},
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
  
    let p0 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.admin.view);

    let p1 = uerService.findAllCurrent();
    p1.then(data => this.list = data);

    let p2 = agerService.findAllCurrentForAdmin();
    p2.then(data => this.agerList = data);

    Promise.all([p0, p1, p2]).then(()=>{
      this.loading = false;
      this.getRef().hideLoader();
    });
  },
  methods: {
    create() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;
      
      this.getRef().showTotalLoader();
      uerService.create(this.current).then(data => {
        this.list.push(data);
        this.current = {};
        
        this.getRef().success(this.$t("uer.added"));
        this.$refs.dialogCreate.hide();
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    update() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;
      
      this.getRef().showTotalLoader();
      uerService.update(this.current).then(data => {
        this.replaceInListById(this.list, data);
        this.current = {};
        
        this.getRef().success(this.$t("uer.updated"));
        this.$refs.dialogUpdate.hide();
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    delete() {
      this.getRef().resetMessages();
      uerService.delete(this.current).then(data => {
        this.removeInListById(this.list, data.id);
        this.getRef().success(this.$t("uer.deleted"))
      }).catch(e => {
          this.getRef().error(e.response.data.error);
      });
    },

    openCreateDialog() {
      this.current = {};
      this.$refs.dialogCreate.show();
    },
    openEditDialog(data) {
      this.current = {...data};
      this.$refs.dialogUpdate.show();
    },
    openDeleteDialog(data) {
      this.current = data;
      this.$refs.dialogDelete.show();
    },
  },
}
</script>

<template v-if="!roleLoading && this.canViewAdmin()">
  <AkFormList :ref="ref" :title="$t('uer.list')">
    <template v-slot:action>
      <button v-if=this.canEditAdmin() class="btn btn-inverse-primary float-right" @click="openCreateDialog()">
        <i class="fe fe-plus pr-1"/>
        {{ $t('add') }}
      </button>
    </template>
    <template v-slot:list>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable 
                   :always-show-paginator="false" 
                   :loading="loading" 
                   :paginator="false"
                   :value="list"
                   class="table"
                   removableSort
                   sortField="agerLabel"
                   :sortOrder="1"
                   responsiveLayout="scroll"
                   stripedRows>
                  <template #empty>
                    {{ $t("list_empty") }}
                  </template>

                  <Column field="label" :header="$t('uer.label')" :sortable="true">
                    <template #body="slotProps">
                          {{ slotProps.data.label }}
                    </template>
                  </Column>
                  <Column field="agerLabel" :header="$t('ager_label')" :sortable="true">
                    <template #body="slotProps">
                          {{ slotProps.data.agerLabel }}
                    </template>
                  </Column>

                  <Column bodyStyle="text-align: right; overflow: visible"
                          headerStyle=" width: 160px; text-align: center">
                    <template #body="slotProps">
                      <span v-if=this.canEditAdmin() 
                      class="btn btn-xs btn-inverse-primary pointer"
                      @click="openEditDialog(slotProps.data)">
                        <i class="fe fe-edit"/></span>
                      <span v-if=this.canDeleteAdmin() 
                      class="btn btn-xs btn-inverse-danger ml-1"
                      @click="openDeleteDialog(slotProps.data)">
                        <i class="fe fe-trash"/></span>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    
    <template v-slot:extra>
      <AkDialog ref="dialogCreate"
                :title="$t('uer.create_dialog')"
                :validate-label="$t('add')"
                width="450px"
                @validate="this.create()">
        <div class="form-row">
          <AkInputText :label="$t('uer_label')"
              v-model="current.label"
              :validator="v$.current.label" 
              class-name="col-12"/>

          <AkInputText :label="$t('import_key')"
                       v-model="current.importKey"
                       :validator="v$.current.importKey"
                       class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('ager_label')"
              v-model="current.agerId"
              :validator="v$.current.agerId" 
              :options=this.agerList
              option-value="id"
              option-label="label"
              class-name="col-12"/>
        </div>
      </AkDialog>
      
      <AkDialog ref="dialogUpdate"
                :title="$t('uer.edit_dialog')"
                :validate-label="$t('update')"
                width="450px"
                @validate="this.update()">
        <div class="form-row">
          <AkInputText :label="$t('uer_label')"
              v-model="current.label"
              :validator="v$.current.label" 
              class-name="col-12"/>

          <AkInputText :label="$t('import_key')"
                       v-model="current.importKey"
                       :validator="v$.current.importKey"
                       class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('ager_label')"
              v-model="current.agerId"
              :validator="v$.current.agerId" 
              :options=this.agerList
              option-value="id"
              option-label="label"
              class-name="col-12"/>
        </div>
      </AkDialog>
      
      <AkDialog ref="dialogDelete"
                :auto-hide-on-validate="true"
                :cancel-label="$t('no')"
                :title="$t('uer.delete_dialog')"
                :validate-label="$t('yes')"
                width="450px"
                @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span >{{ $t('uer.confirm_delete') }}</span>
        </div>
      </AkDialog>

    </template>
  </AkFormList>
</template>